<template>
  <div>
    <b-row class="card" v-if="load">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-solar-panel"></i> Kit atual
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="12">
            <span>Descrição</span> <br>
            <h4>{{ payload.project.kits.description }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2">
            <b-form-group label="Potência">
              <b-input-group append="Kwh">
                <b-form-input
                  v-model="payload.project.kits.estimate_power_kwh"
                  disabled
                  type="number"
                  min="0"
                  step="0.01"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="2">
              <b-form-group label="Preço">
                <b-input-group prepend="R$">
                  <b-form-input
                    v-model="price"
                    disabled
                    type="number"
                    min="0"
                    step="0.01"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-form-group label="Inversor">
              <b-form-select
                :class="{ 'form-group--error': $v.payload.project.kits.inverter_id.$error }"
                v-model="$v.payload.project.kits.inverter_id.$model"
                :state="$v.payload.project.kits.inverter_id.$dirty ?
                !$v.payload.project.kits.inverter_id.$error : null"
                :options="products.inverters"
                required
                @change="recalculatePrice('inverter', $event )"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group label="Painel">
              <b-form-select
              :class="{ 'form-group--error': $v.payload.project.kits.panel_id.$error }"
              v-model="$v.payload.project.kits.panel_id.$model"
              :state="$v.payload.project.kits.panel_id.$dirty ?
              !$v.payload.project.kits.panel_id.$error : null"
              :options="products.panels"
              @change="recalculatePrice('panel', $event )"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          @click="confirm"
          class="float-right"
        >
          <i class="fas fa-save"></i> Salvar
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ProjectService from '@/Services/ProjectService';
import ProductService from '@/Services/ProductService';
// import ProductCustonService from '@/Services/ProductCustonService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
// import Multiselect from 'vue-multiselect';

export default {
  props: ['project'],
  data() {
    return {
      originalPrice: 0,
      load: false,
      inverters: [],
      payload: {
        transformer_id: 1,
        project: {
          kits: {
            inverter_id: '',
            panel_id: '',
            roof_type_id: '',
            estimate_power_kwh: '',
          },
        },
      },
      products: {
        inverters: [
          {
            text: 'Selecione um inversor',
            value: '',
          },
        ],
        transformers: [],
        panels: [],
      },
      payloadProduct: {
        type: '',
        description: '',
        power: '',
      },
      spinner: false,
    };
  },
  computed: {
    price() {
      return this.payload.project.price.toFixed(2);
    },
  },
  validations: {
    inverters: {
      required,
    },
    payload: {
      project: {
        kits: {
          inverter_id: {
            required,
          },
          panel_id: {
            required,
          },
          roof_type_id: {
            required,
          },
        },
      },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getProject() {
      try {
        const { data } = await ProjectService.get(this.project);
        this.payload = data;
        this.payload.transformer = data.transformer;

        this.payload.project.kits = {
          ...this.payload.project.kits[0],
        };

        this.load = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getProducts() {
      try {
        const { data } = await ProductService.getList();
        this.payload.project.kits.estimate_power_kwh = parseInt(this.payload.project.kits.estimate_power_kwh, 10);

        data.inverters.forEach((item) => {
          this.products.inverters.push({
            text: item.description,
            value: item.id,
            ...item,
          });
        });

        data.panels.filter((el) => el.active).forEach((item) => {
          this.products.panels.push({
            text: item.model,
            value: item.id,
            ...item,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    recalculatePrice(type, id) {
      if (type === 'inverter') {
        const inverter = this.products.inverters.find((item) => item.id === id);
        let diff = inverter.price - this.payload.project.kits.inverter.price;

        diff += diff * this.payload.percentage_adm;
        if (diff > 0) {
          this.payload.project.price += parseFloat(diff);
        } else {
          this.payload.project.price -= Math.abs(diff);
        }
        this.payload.project.kits.inverter = inverter;
      }

      if (type === 'panel') {
        const panel = this.products.panels.find((item) => item.id === id);
        // eslint-disable-next-line no-unused-vars
        let diff = 0;
        if (this.payload.project.kits.panel !== undefined) {
          diff = panel.price - this.payload.project.kits.panel.price;
          console.log(diff);
          if (diff > 0) {
            this.payload.project.price += parseFloat(diff);
          } else {
            this.payload.project.price -= Math.abs(diff);
          }
        } else {
          diff = panel.price;
          this.payload.project.price += parseFloat(diff);
        }

        diff += diff * this.payload.percentage_adm;

        this.payload.project.kits.panel = panel;
        console.log(this.payload.project.kits.panel);
      }
    },
    confirm() {
      this.$toast.question(
        'Deseja realmente alterar o projeto ?',
        'Atenção',
        this.getQuestionObj(this.save, '', 'Projeto alterado'),
      );
    },
    async save() {
      try {
        this.spinner = true;
        const payload = {
          project_id: this.payload.project.id,
          panel_id: this.payload.project.kits.panel_id,
          inverter_id: this.payload.project.kits.inverter_id,
          price: this.payload.project.price,
        };
        await ProjectService.updateInverter(payload);
        this.$toast.success('Projeto alterado com sucesso');
        this.$router.push({ name: 'project', params: { id: this.payload.project.id } });
      } catch (error) {
        this.$toast.error('Erro ao alterar projeto');
        console.log(error);
      } finally {
        this.spinner = false;
      }
    },
  },
  created() {
    this.getProject();
    this.getProducts();
  },
};
</script>
